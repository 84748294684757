const burger = document.querySelector('[data-behaviour="burger"]');
const mobileNav = document.querySelector('[data-behaviour="mobile-nav"]');
const body = document.body;

if (burger) {
    const closedClass = 'closed';
    const openClass = 'open';

    burger.addEventListener('click', (e) => {
        e.preventDefault();
        body.classList.toggle('locked');
        const svg = burger.querySelector('svg');

        svg.classList.toggle('active');
        mobileNav.classList.toggle(openClass);
        mobileNav.classList.toggle(closedClass);
    });
}
