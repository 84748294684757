const nav = document.querySelector('.site-header');
const navHeight = 50;
let lastScrollY = 0;
const delta = 10;

function scrolled() {
    let sy = window.scrollY;
    if (Math.abs(lastScrollY - sy) > delta) {
        if (sy > lastScrollY && sy > navHeight) {
            nav.classList.add('site-header--hidden');
        }
        // scroll up -> show nav bar
        else if (sy < lastScrollY) {
            nav.classList.remove('site-header--hidden');
        }
        // update current scroll point
        lastScrollY = sy;
    }
}

let didScroll = false;
window.addEventListener('scroll', function (e) {
    didScroll = true;
});

setInterval(function () {
    if (didScroll) {
        scrolled();
        didScroll = false;
    }
}, 100);
